.container-stats {

    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    .block-stats {

        flex-grow: 1;
        flex-basis: 50%;
        flex-shrink: 1;
        padding: 10px;
    }

}