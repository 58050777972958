.tagForm {
    margin-top: 10px!important;
}
.highlight:before {
    color: #1FD5B9;
}
.pointer {
    cursor: pointer!important;
}
.dragging-helper-class {
    background-color: white;
    display: table;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 10px;
    vertical-align: middle;
  }
  