.roboto-bold {
    font-family: RobotoBold!important;
}

.poppins-semibold {
    font-family: PoppinsSemiBold!important;
}
.pink-color {
    color: var(--y-green1)!important;
}
.grey3 {
    color: var(--grey3);
}
.grey4 {
    color: var(--grey4);
}
.yellow{
    color: var(--yellow);
}

.verticalPad {
    padding-top: 15px;
    padding-bottom: 15px;
}

.topPad{
    padding-top: 15px;
}

.verticalPad-small {
    padding-top: 8px;
    padding-bottom: 8px;
}
.verticalPadBottom {
    padding-bottom: 15px;
}
.verticalPad-long {
    padding: 50px 0;
}
.f-left{
    float: left;
}
.f-right {
    float: right;
}
.upper {
    text-transform: uppercase!important;
}

.resetMargin{
    margin: 0!important;
}

.t-center {
    text-align: center!important;;
}

.t-left {
    text-align: left!important;;
}

.justify {
    text-align: justify!important;
}

.disabled-nav{
    opacity: .3;
}

.flex-center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mirror{
    transform: scaleX(-1);
}

.blurred {
    filter: blur(5px) grayscale(.7);
}

.hide-modal{
    
    &.visible{
        &.transition {
            display: none!important;
        }
    }
}

.floating {

    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 15px!important;;
    @media only screen and (max-width: 420px) {
        top: 10px;
        right: 10px;
        font-size: 13px!important;;
    }
}

.hide-video {
    display: none!important;
}

.video-transition {
    position: absolute;
    top: 0;
    left: 0;
}

.c-pointer {

    cursor: pointer!important;
}