.faq-box {

    max-width: 820px;
    margin: 80px auto;
    width: 100%;
    
    .faq-row {
        background-color: var(--grey2);
        color: var(--grey6);
        margin-bottom: 8px;
        .faq-header{
            padding: 30px;
            font-size: 36px;
            line-height: 36px;
            font-family: RobotoBold;
            position: relative;
            border-bottom: solid 2px black;
            .faq-title {
                padding-right: 40px;
            }
            .closure {
                position: absolute;
                display: inline-block;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                &.rotate {
                    transform: translateY(-50%) rotate(-45deg);
                    
                }
            }
            @media only screen and (max-width: 720px) {
                font-size: 28px;
            }
            @media only screen and (max-width: 520px) {
                font-size:20px;
                padding: 15px;
            }
        }
        .faq-body {
            padding: 30px;
            font-family: RobotoRegular;
            font-size: 32px;
            display: none;
            &.active {
                display: block;
            }
            @media only screen and (max-width: 720px) {
                font-size: 26px;
                }
            @media only screen and (max-width: 520px) {
                font-size:18px;
                padding: 15px;
            }
        }
        
    }

    &.alternative {
        .faq-row { 
            background-color: var(--grey5);
            color: var(--grey3);
            .faq-header{
                border-bottom: solid 2px white;
            }
        }
        
    }

    @media only screen and (max-width: 520px) {
        margin: 40px auto;
    }
}