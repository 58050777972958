/********************************PREDEFINED BUTTONS ********************************/

.custom-btn {

    height: 55px;
    cursor: pointer;

    &.default {

        height: auto;
        padding: 5px 25px;
        background-color: var(--y-green1);
        font-family: PoppinsRegular;
        font-size: 24px;
        line-height: 36px;
        color: var( --y-grey3);
        &:hover {
            background-color: var(--y-green1);
        }

        &.invert{
            color: var(--y-green1);
            background-color: var( --y-grey3);

            &:active{
                background-color: var( --y-grey3);
            }
            &:focus{
                background-color: var( --y-grey3);
            }
            
        }
        
        &:active{
            background-color: var( --y-green1);
        }
        &:focus{
            background-color: var( --y-green1);
        }
    }

    &.fb {
        background-color: var(--blue9)!important;
        color: #FFF!important;
    }

    &.google {
        background-color: #FFF!important;
        border: solid 1px var(--grey5) !important;
    }

    &.brand {
        background-color: var(--y-green1)!important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #FFF!important;
        text-transform: uppercase;
        &.invert{
            background-color: var(--y-grey9) !important;
            
        }
        
    }
    &.simple {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background-color: #FFF!important;
        border: solid 1px var(--y-grey5) !important;
        color: var(--y-grey5) !important;
    }

    &.small {
        height: 33px;
        font-size: 14px;
        min-width: 124px;
        line-height: 5px;
    }

    &.no-shadow {
        box-shadow: unset!important
    }

    &.rounded {
        background-color: white!important;
        color: var(--blue10)!important;
        box-sizing: border-box;
        border-radius: 19px;
        border: 1px solid var(--blue10);
    }
}
