#root {
    flex-grow: 1;
    min-height: 100%;
    .statSegment {

         text-align: center;
        .statistic-logo {
            margin: auto;
        }

        #chartdiv {
            height: calc(100% - 180px);
            left: -5px;
        }

    }

}

body {
    display: flex;
}

//////////////////////////////////////////////////////////// Overrides /////////////////////////////////////////////////////////////////////////////////////

// Overrides charts
g[aria-labelledby$="-title"] {
    display: none;
}

// Overrides datepicker
.ui.icon.input[data-testid='datepicker-input'] {
    width: 225px;
}

.icon-sidebar {
    position: relative;
    padding: 20px;
    z-index: 1;
}


//overrides error message label

.ui.basic.red.label {
    background:#FF325D!important;
    // display: block!important;
    color: white!important;
}

//custom css for react-toastify

.Toastify__toast{
    font-family: PoppinsRegular;
}

.Toastify__toast--success {

    &.r-toast-success {
        background: var(--y-green1);
    }

    &.r-toast-error {
        background: var(--red4);
    }
}

.cbrand {
    color: var(--y-green1)!important;
} 

.icon-wallet {
    margin-right: 10px;
    &::before {
        color: var(--y-green1);
        font-size: 20px;
    }
}