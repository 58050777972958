.home-modal {

    &.ui {

        &.modal {

            &>.header {
                background-color: var(--y-grey3);
                i{  
                    font-size: 24px;
                    &:before{
                        color: var(--y-green1);
                        text-shadow: 0 0 15px var(--y-green1);
                    }
                }
            }

            &>.content {
                font-family: PoppinsRegular;
                color: var(--y-grey6);
            }
        }

    }

}