.discount-modal {

    &.ui {

        &.modal {

            &>.header {
                background-color: var(--y-grey3);
                i{  
                    font-size: 24px;
                    &:before{
                        color: var(--y-green1);
                        text-shadow: 0 0 15px var(--y-green1);
                    }
                }
            }

            .ui.form {
                &>.content {
                    font-family: PoppinsRegular;
                    color: var(--y-grey6);
                    display: block;
                    width: 100%;
                    font-size: 1em;
                    line-height: 1.4;
                    padding: 1.5rem;
                    background: #fff;
                }
            }
            
        }

    }

}

.actions-btns {
    background: #f9fafb;
    padding: 1rem 1rem;
    border-top: 1px solid rgba(34,36,38,.15);
    text-align: right;
}