.error-message-box{
    background: var(--red4);
    display: block;
    color: white;
    font-family: PoppinsRegular;
    font-size: 14px;
    padding: .5833em .833em;
    border-radius: .28571429rem;
    margin-top: 15px;
    margin-bottom: 15px;
    a{
        color: white!important;
        text-decoration: underline;
    }
}