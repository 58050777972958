
.wrapper-login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/img/meteoros.png');
    background-color: var(--y-grey3);
	background-repeat: no-repeat;
	background-position: top right;
    .box {
        width: 660px;
        min-height: 654px;
        padding: 20px;
        box-sizing: border-box;
        .logo, .correct-logo, .logo-mobile {
            text-align: center;
            margin: 0 auto;
            img {
                max-width: 100%;
            }
        }

        .logo {
            display: none;
            padding-top: 30px;
            @media only screen and (min-width: 420px) {
                display: block;
            }
        }

        .logo-mobile {
            display: none;
            padding-top: 40px;
            @media only screen and (max-width: 419px) {
                display: block;
            }
        }

        .correct-logo{
            padding-top: 30px;
            @media only screen and (max-width: 420px) {
                width: 250px;
            }
        }

        .rrss-title{
            text-align: center;
            color:white;
            font-family: PoppinsRegular;
            font-size: 20px;
        }

        .rrss-subtitle{
            text-align: center;
            color:var(--y-green1);
            font-family: PoppinsMedium;
            font-size: 24px;
            line-height: 36px;
            margin: 20px auto;
        }
        .rrss-icons {
            display: grid;
            grid-template-columns: 81px 20px 81px;
            padding: 10px;
            width: 203px;
            background-color: var(--y-grey14);
            border-radius: 3px;
            margin: 20px auto;
            .item {
                height: 60px;
                width: 100%;
                padding: 5px;
                text-align: center;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                cursor: pointer;
                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                &.fb{
                    background-color: var(--y-blue1);
                }
                &.gl{
                    background-color: var(--y-red1);
                }
            }

            .middle-bar {
                width: 3px;
                height: 36px;
                background-color: var(--y-grey9);
                margin: 0 auto;
                align-self: center;
            }
            
        }

        .separator-bar {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 94px 1fr;
            .bar {
                width: 100%;
                height: 1px;
                background-color: var(--y-grey14);
                align-self: center;
            }
            .circle {
                color: var(--y-green1);
                font-family: PoppinsMedium;
                font-size: 24px;
                text-align: center;
            }
        }

        .correct-message-title {
            padding-top: 72px;
            color:white;
            font-family: PoppinsBold;
            font-size: 20px;
            text-align: center;
            @media only screen and (max-width: 420px) {
                 font-size: 18px;
            }

        }

        .correct-message-subtitle {
            
            color:white;
            font-family: PoppinsRegular;
            font-size: 20px;
            text-align: center;
            margin-top: 10px;
            @media only screen and (max-width: 420px) {
                 font-size: 18px;
            }
            
        }
        
        .title {
            font-size: 40px;
            font-family: PoppinsMedium;
            color: var(--y-green1);
            text-align: center;
            padding-top: 72px;
            line-height: 45px;
            span {
                font-size: 30px;
                color: white;
                font-family: PoppinsRegular;
                @media only screen and (max-width: 420px) {
                    font-size: 24px;
               }
            }
            
            @media only screen and (max-width: 420px) {
                font-size: 32px;
                padding-top: 32px;
           }

        }
        
        .text {
            padding-top: 72px;
            color: white;
            font-family: PoppinsRegular;
            font-size: 18px;
            text-align: left;
            padding-bottom: 32px;
            @media only screen and (max-width: 420px) {
                font-size: 16px;
                padding-top: 32px;
           }
        }

        .forgot-password {
            position: relative;
            .forgot-password-text{
                position: absolute;
                top: 22px;
                right: 0;
                color: white;
                font-size: 14px;
                font-family: PoppinsRegular;
                cursor: pointer;
                @media only screen and (max-width: 420px) {
                    font-size: 12px;
               }
            }
        }

        .y-input {
            label{
                font-size: 16px !important;
            }
        }

        .descriptions {
            display: flex;
            color: white;
            font-size: 14px;
            line-height: 21px;
            font-family: PoppinsRegular;
            font-weight: normal;
            margin-top: 15px;
            &.first {
                justify-content: space-between;
                
            }
            &.second {
                margin-top: 0;
                span{
                    margin-top: 8px;
                }
                button{
                    padding: 5px 43px;
                    margin: 0!important;
                    @media only screen and (max-width: 420px) {
                        width: 100%;
                   }
                }
            }

            span {
                cursor: pointer
            }
            @media only screen and (max-width: 420px) {
                font-size: 12px;
           }
        }

        @media only screen and (max-width: 660px) {
            width: 100%;
            padding: 32px;
        }
    }
}