:root {

    --white: white;
    --grey1: #4F4F4F;
    --grey2: #4F4F4F;
    --grey3: #828282;
    --grey4: #BDBDBD;
    --grey5: #E0E0E0;
    --grey6: #F2F2F2;
    --pink: #FB4081;
    --blue1: #242F56;
    --blue2: #4269B0;
    --blue3: #2D9CDB;
    --blue4: #50E3C2;
    --blue5: #33BDC2;
    --blue6: #4A90E2;
    --blue7: #16559F;
    --blue8: #022651;
    --blue9: #4063DF;
    --blue10: #394ABC;
    --orange1: #E2684B;
    --red1: #DB0000;
    --red2: #EF4D4D;
    --red3: #FFA0A0;
    --red4: #FF325D;
    --yellow: #FFB53F;
    --y-grey1:#99B0BC;
    --y-grey2:#8CA3B0;
    --y-grey3:#31425A;
    --y-grey4:#E9ECED;
    --y-grey5:#393939;
    --y-grey6:#9E9E9E;
    --y-grey7:#31425A;
    --y-grey8:#F1F6F8;
    --y-grey9:#838E9C;
    --y-grey10:#EDF1F2;
    --y-grey11:#505865;
    --y-grey12:#CFDEE2;
    --y-grey13:#B6B6B6;
    --y-grey14:#C4C4C4;
    --y-grey15:#615F5F;
    --y-grey16:#AFADAD;
    --y-grey17:#E1E8E6;
    --y-green1:#1FD5B9;
    --y-green2:#06f9d4;
    --y-blue1:#1A367C;
    --y-blue2:#31435b;
    --y-red1: #DB4A38;
    --y-color-shadow: #1C2C43;
    
}